import React from "react"
import {isNullUndefined} from "../../../util/"
import {getDefaultNonUkResidentsHcpDisclaimerPopupConfigs} from "../../../util/configs"

export function toggleNonUkResidentsHcpDisclaimerPopup(e, urlToVisit, openPopup){
    e.preventDefault()
    if(isNullUndefined(urlToVisit)){
        return;
    }
    let okCallback = () => window.open(urlToVisit, "_blank")
    let closeCallback = () => {}
    let popupConfig = getDefaultNonUkResidentsHcpDisclaimerPopupConfigs(okCallback, closeCallback);
    openPopup(popupConfig)
}